import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const English_As = () => {
  return (
    <>
    <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
    <BannerV5
        headerTitle={"English Assignments Help"}
        typeWriterValue={[
            "Put a stop to deadline pressure, and have your homework done  ",
            "by an expert.",
            // "ASSIGNMENT",
            // "PROJECT REPORT",
        ]}
        smallText={
            ""
        }
    />
    <div className="container mt-100 mb-100">
        <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">182 300+</Link>
                        </h3>
                        <h6>
                            <Link to="#">DELIVERED ORDERS</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5844 +</Link>
                        </h3>
                        <h6>
                            <Link to="#">EXPERTS</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">4.9/5</Link>
                        </h3>
                        <h6>
                            <Link to="#">CLIENT RATING</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5+ Countries</Link>
                        </h3>
                        <h6>
                            <Link to="#">Operated in 5+ Countries</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
<Link className="ltn__service-btn" to="/contact">
Order now <i className="flaticon-right-arrow" />
</Link> */}
                    </div>
                </div>
            </div>
        </div>
        <h4>Selecting The Best Person To Do My English Assignment</h4>
        <p>So, you’ve entered the university of your dreams in an English-speaking country, and everything seems to go as planned. Then what is it that makes you say “do my English assignment”? Well, first of all, you may be prone to having ESL issues and face difficulties when trying to compose your English assignment. Secondly, you may have problems dealing with confusing topics from your professor. Finally, the chances are that currently, you have a pile of homework to do, not to mention different social commitments and student activities. With such a shortage of time and the pressure of studies, who would blame you for seeking English Assignments Help online?</p>
       
        <p>Finding writers to give you English Assignments Help is a process that should not be taken lightly. There are certain qualities and questions you should look for when selecting somebody, as this will ensure that you receive the best help with English homework that you can possibly get.</p>
        <h4>Experience With Providing Help With English Assignment</h4>
        <p>It is important that you find out exactly what type of assistance has been given before, so that you know if it will be affordable and suitable for you. The Assignments Help is lucky enough to have experts with all different specialist subjects, so you can buy whatever type of help you need. Just tell us about your next project and we will assist you with selecting the right person for the job.Writing</p>

        <h4>Training</h4>
        <p>As well as experience and knowledge, you should ask about the training that has been given to the writer. Assignments Help greatly values its team, so we place a lot of emphasis on training and upholding a certain high standard across the whole group, regardless of the type of custom help with English assignment you require. Your writer will be able to tell you about the following:</p>
        <ul>
        <li>School qualifications;</li>
        <li>Further education;</li>
        <li>Writing-specific courses;</li>
        <li>Training undertaken with us;</li>
        <li>Personal interests that contribute to professional writing abilities.</li>
      </ul>
        <h4>Communication</h4>
        <p>In order to create a great piece of writing, we know that communication is absolutely key when you ask someone to do my English assignment. When you make an order with us, we continue communicating with you throughout the project; in this way, we can keep you up-to-date on each stage of the task and let you know what is being included in the document. You will also have direct contact details, so you are welcome to get in touch with us at any time. We are available 24 hours a day, so you will always be able to reach out to someone here.</p>
        <h4>Nursing Assignments Help Is Easy With Our Service</h4>
        <p>Our service is really awesome because we have a customized approach to every client we get. You may choose your own assignment writer, and he will deliver custom papers that are always 100% unique. Your content won’t be reused or recycled ever, so you’ll feel confident that your teacher will enjoy your submission. We’ll even send over some samples when you contact us, so that you can review how quality our assistance is and make an informed decision on buying nursing homework assignments.</p>
        <h4>Time To Do My Nursing Assignment? Contact Us Now!</h4>
        <p>Assignment Help understands that when you order services, you have to feel confident about what you’re going to receive. That’s why we provide a satisfaction guarantee as you buy nursing assignment help from us. Select us in confidence and you’ll always get great content. Are you ready to get your homework done? Get the nursing Assignments Help you need by contacting us now!</p>
      

    </div>

    {/* <CallToActionV1 /> */}
    <Footer />
</>
  )
}

export default English_As
