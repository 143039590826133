import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Accounting = () => {
    return (
        <>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
            <BannerV5
                headerTitle={"Accounting Assignments Help"}
                typeWriterValue={[
                    "Put a stop to deadline pressure, and have your homework done  ",
                    "by an expert.",
                    // "ASSIGNMENT",
                    // "PROJECT REPORT",
                ]}
                smallText={
                    ""
                }
            />
            <div className="container mt-100 mb-100">
                <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">182 300+</Link>
                                </h3>
                                <h6>
                                    <Link to="#">DELIVERED ORDERS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5844 +</Link>
                                </h3>
                                <h6>
                                    <Link to="#">EXPERTS</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">4.9/5</Link>
                                </h3>
                                <h6>
                                    <Link to="#">CLIENT RATING</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12" >
                        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                            <div className="ltn__feature-icon">
                                <img
                                    src={"assets/img/icons/icon-img/22.png"}
                                    alt="#"
                                />
                            </div>
                            <div className="ltn__feature-info">
                                <h3>
                                    <Link to="#">5+ Countries</Link>
                                </h3>
                                <h6>
                                    <Link to="#">Operated in 5+ Countries</Link>
                                </h6>
                                {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Get Help With Your Accounting Assignment Online</h4>
                <p>Let us guess – you’ve taken an accounting course at your college or university, and while it is really rewarding, it is also extremely hard. Chances are, you are struggling with homework tasks to no avail, and desperately want to get accounting homework help from the esteemed experts. But now you can forget your worries, as it’s easy to find accounting Assignments Help  online with Assignment Help. That’s because our writers understand how to complete complex account problems and balance your books. Whether you’re dealing with accounts receivable or accounts payable, we know the exact steps that need to be taken to solve your work problem. We’ll give you the accounting the assignment writing you need in a timely manner. Hundreds of students and professional workers from around the world turn to us for help with accounting assignment.</p>
                <h4>Why Should I Get Someone To Do My Accounting Assignment?</h4>
                <p>So, you’ve made a decision to get accounting homework help. The next step is even more difficult – how do you find expert helpers, capable of handling your accounting assignments at the highest level? Well, if you need a professional accounting homework help, trust a quality writing company instead of one of your friends. By working with us, you know that we’ll verify all of your submissions before turning anything into you. You’ll rest easy that your work is accurate. Additionally, we provide custom content packages that you can’t receive anywhere else. Our support team is working 24/7, ready to receive your order anytime day or night, plus, if you need some minor changes to be made in your paper, our writers will be glad to help you at no extra cost! So, stop bugging your big brother to “do my accounting assignment” and order from pros instead.</p>

                <h4>Accounting Help Online Is Just A Click Away</h4>
                <p>Teachers want to know that you understand the problems you’re assigned, and that you’re doing the math correctly. Essentially, everything should balance up. Accounting work is all about understanding the process, and doing it accurately. It’s simple to do once you’ve been fully trained. Here’s why people go through our company:</p>
                <p>1. We provide high quality accounting homework help that meets your requirements and your deadlines.</p>
                <h4>Why Choose Us To Get Help With Business Homework</h4>
                <p>2. We have professionals that have been trained to write about and complete this type of work.</p>
                <p>3. You’ll get a good grade and spend less by going through us.</p>
                <p>4. We’ve done this type of work for years as a leading provider.</p>
                <p>5. We have many different writers to work with, many of which have the experience needed to complete you job.</p>
                <h4>Trust Assignments Help To Be Your Accounting Homework Helper!</h4>
                <p>Are you looking for incredible service? Do you want access to experts that understand business math and writing? If you’ve said yes to these questions, then you should go with our assistance now! We provide affordable help that’s guaranteed to be quality. There’s no place else with better content. We make the whole process of getting your work done effortless, and we know you’ll have a wonderful experience or your money back. Try us out risk free and quit struggling to complete your work. Give us a call or email us today to get in touch with our professional writers. Don’t wait until your assignment is almost due, act today!</p>


            </div>

            {/* <CallToActionV1 /> */}
            <Footer />
        </>
    )
}

export default Accounting
