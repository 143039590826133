import React from 'react'
import Navbar from "./global-components/navbar-v3";
import BannerV5 from "./section-components/banner-v5";
import Footer from "./global-components/footer";
import { Link } from "react-router-dom";

const Nursing = () => {
  return (
    <>
    <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
    <BannerV5
        headerTitle={"Nursing Assignments Help"}
        typeWriterValue={[
            "Put a stop to deadline pressure, and have your homework done  ",
            "by an expert.",
            // "ASSIGNMENT",
            // "PROJECT REPORT",
        ]}
        smallText={
            ""
        }
    />
    <div className="container mt-100 mb-100">
        <div className="row ltn__custom-gutter--- justify-content-center go-top mb-50">
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">182 300+</Link>
                        </h3>
                        <h6>
                            <Link to="#">DELIVERED ORDERS</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5844 +</Link>
                        </h3>
                        <h6>
                            <Link to="#">EXPERTS</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">4.9/5</Link>
                        </h3>
                        <h6>
                            <Link to="#">CLIENT RATING</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12" >
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
                    <div className="ltn__feature-icon">
                        <img
                            src={"assets/img/icons/icon-img/22.png"}
                            alt="#"
                        />
                    </div>
                    <div className="ltn__feature-info">
                        <h3>
                            <Link to="#">5+ Countries</Link>
                        </h3>
                        <h6>
                            <Link to="#">Operated in 5+ Countries</Link>
                        </h6>
                        {/* <p>{data.discription}</p>
              <Link className="ltn__service-btn" to="/contact">
                Order now <i className="flaticon-right-arrow" />
              </Link> */}
                    </div>
                </div>
            </div>
        </div>
        <h4>Quality Nursing Assignments Help</h4>
        <p>It is a known fact that the field of nursing and traditional medicine encompasses a number of difficult sub-disciplines and challenging assignments, which should be submitted by students all over the world. Although all of them seek to pursue a successful nursing career in the future, not every student has enough time to deal with tonns of nursing assignments and projects. That’s why we have nursing assignment writers that have been trained to tackle complicated tasks and provide best help with nursing assignments. They’ve taken the same classes you have and understand the language needed to get you a good grade.</p>
        <h4>Get Nursing Assignment Help From Assignments Help</h4>
        <p>It is a known fact that the field of nursing and traditional medicine encompasses a number of difficult sub-disciplines and challenging assignments, which should be submitted by students all over the world. Although all of them seek to pursue a successful nursing career in the future, not every student has enough time to deal with tonns of nursing assignments and projects. That’s why we have nursing assignment writers that have been trained to tackle complicated tasks and provide best help with nursing assignments. They’ve taken the same classes you have and understand the language needed to get you a good grade.</p>

        <h4>Get Nursing Assignment Help From Assignments Help</h4>
        <p>A quality nursing Assignments Help service provider understands that your content has to be of the highest level of quality possible. Chances are, your professor will look for both understanding of the content, as well as the technical use of the English language. When you buy nursing assignment help from us, you get submissions that are grammatically correct. All of the words will convey the message it’s supposed to, without any fluff that bogs down academic papers.</p>
       <h4>You'd Better Prioritize Your Computer Science Homework</h4>
       <p>When you go with us, you get much more than you do with other content providers. We work hard to go above and beyond the work that other people create. That’s because we’ve worked in this field for many years, and understand what it is that our clients need. We’ll give you the best content, 100% of the time.</p>
       <p>That’s why we provide services that include:</p>
       <p>Covering all nursing assignment topics</p>
       <p>Looking over your writing assignment syllabus to ensure we cover all topics</p>
       <p>Verifying with you any revisions that need to be completed</p>
       <p>Producing unique and quality service that’s affordable</p>
       <h4>Nursing Assignments Help Is Easy With Our Service</h4>
       <p>Our service is really awesome because we have a customized approach to every client we get. You may choose your own assignment writer, and he will deliver custom papers that are always 100% unique. Your content won’t be reused or recycled ever, so you’ll feel confident that your teacher will enjoy your submission. We’ll even send over some samples when you contact us, so that you can review how quality our assistance is and make an informed decision on buying nursing homework assignments.</p>
       <h4>Time To Do My Nursing Assignment? Contact Us Now!</h4>
       <p>Assignment Help understands that when you order services, you have to feel confident about what you’re going to receive. That’s why we provide a satisfaction guarantee as you buy nursing assignment help from us. Select us in confidence and you’ll always get great content. Are you ready to get your homework done? Get the nursing Assignments Help you need by contacting us now!</p>
      
    </div>

    {/* <CallToActionV1 /> */}
    <Footer />
</>
  )
}

export default Nursing
